import { mapMutations, mapState, mapGetters } from 'vuex';
import { paginationMixin } from '@/modules/shared/mixins/paginationMixin';

import KnLocalAlert from '../../../shared/components/KnLocalAlert.vue';
import KnTable from '@/modules/shared/components/KnTable.vue';
import { formatStringDateToLocaleDate } from '../../../shared/helpers/dateUtils';
import {
  fetchAnnouncements,
  delAnnouncement,
  reactivateAnnouncement,
  resendAnnouncementEmails,
} from '../../helpers/announcementOptions';
import {
  canAdd,
  canChange,
  canDelete,
  insufficientPermissionsMessage,
} from '../../../shared/helpers/permissionsUtils';
export default {
  name: 'AnnouncementIndex',
  components: { KnLocalAlert, KnTable },
  mixins: [paginationMixin],
  props: {},
  data() {
    return {
      announcements: [],
      headers: [
        {
          text: 'Fecha de elaboración',
          value: 'fecha_elaboracion',
          class: 'purple--text',
        },
        { text: 'Nombre', value: 'nombre_anuncio', class: 'purple--text' },
        {
          text: 'Audiencia',
          value: 'audiencia', // Se debe mostrar el grupo o Todos si no tiene
          class: 'purple--text',
        },
        { text: 'Vigencia', value: 'vigencia', class: 'purple--text' }, // fecha de inicio y fin concatenados
        { text: 'Autor', value: 'autor', class: 'purple--text' },
        { text: 'ID', value: 'id', class: 'purple--text' },
        { text: '', value: 'acciones', class: 'purple--text' },
      ],
      items: [],
      inactiveItems: [],
      // search: null,
      showInactive: false,
      loading: false,
      /** Variables para alerta */
      errors: [],
      warnings: [],
      loadingAlert: false,
      showAlert: false,
      alertType: 'info',
      alertText: insufficientPermissionsMessage(),
      alertColor: 'info',
      /*********************** */
    };
  },
  computed: {
    ...mapState(['institutionId', 'userData', 'search']),
    ...mapGetters(['isBasicEmployee', 'isSuperAdmin', 'userId']),
    getEmployeeId() {
      return this.isBasicEmployee
        ? this.userData.perfiles[3].empleado[0].id
        : null;
    },
    buttomActionText() {
      return this.showInactive
        ? 'Ver Anuncios Activos'
        : 'Ver Anuncios Inactivos';
    },
    tableTitle() {
      return this.showInactive ? 'Anuncios Inactivos' : 'Anuncios';
    },
  },
  watch: {
    search: {
      async handler() {
        await this.getPaginatedAnnouncements(1);
      },
    },
    institutionId: {
      async handler() {
        this.setIsLogin(false);
        await this.getPaginatedAnnouncements(1);
      },
    },
    showInactive: {
      async handler() {
        await this.getPaginatedAnnouncements(1);
      },
    },
  },
  async created() {
    this.setIsLogin(false);
    await this.getPaginatedAnnouncements(1);
  },
  methods: {
    ...mapMutations(['setIsLogin']),
    canAdd: canAdd,
    async getPaginatedAnnouncements(page = 1) {
      this.setPaginationPage(page);
      if (!this.loading) {
        this.items = [];
        this.loading = true;
        const { ok, data, message, count } = await fetchAnnouncements({
          institutionId: this.institutionId,
          authorId: this.isSuperAdmin ? null : this.userId,
          announcementName: this.search,
          systemStatus: !this.showInactive,
          limit: this.pagination.limit,
          offset: this.pagination.offset,
        });
        if (ok) {
          this.setPaginationCount(count);
          const announcements = data;
          for (const announcement of announcements) {
            const groupNames =
              announcement.grupos && announcement.grupos.length
                ? announcement.grupos.map((g) => g.nombre_grupo)
                : [];
            const item = {
              ...announcement,
              fecha_elaboracion: formatStringDateToLocaleDate({
                stringDate: announcement.fecha_elaboracion,
              }),
              audiencia: announcement.es_general
                ? 'General'
                : groupNames.length
                ? groupNames.join(', ')
                : 'Sin asignar',
              vigencia: `${formatStringDateToLocaleDate({
                stringDate: announcement.fecha_inicio,
              })} - ${formatStringDateToLocaleDate({
                stringDate: announcement.fecha_fin,
              })}`,
              autor: announcement.autor
                ? announcement.autor.username
                : 'Sin asignar',
            };
            this.items.push(item);
          }
        } else {
          console.error('Error al obtener anuncios. ', message);
        }
        this.loading = false;
      }
    },
    async action2(announcementId) {
      if (this.showInactive) {
        await this.enableAnnouncement(announcementId);
      } else {
        await this.disableAnnouncement(announcementId);
      }
    },
    async disableAnnouncement(announcementId) {
      try {
        if (canDelete('anuncio')) {
          const { ok, message } = await delAnnouncement(announcementId);
          if (ok) {
            await this.getPaginatedAnnouncements(1);
          } else {
            console.log('No se pudo desactivar el anuncio. ', message);
          }
        } else {
          this.showAlert = true;
        }
      } catch (error) {
        console.error('Error al intentar desactivar anuncio. ', error);
      }
    },
    async enableAnnouncement(announcementId) {
      try {
        if (canChange('anuncio')) {
          const { ok, message } = await reactivateAnnouncement(announcementId);
          if (ok) {
            await this.getPaginatedAnnouncements(1);
          } else {
            console.log('No se pudo reactivar anuncio. ', message);
          }
        } else {
          this.showAlert = true;
        }
      } catch (error) {
        console.error('Error al intentar reactivar anuncio. ', error);
      }
    },
    async resendSelectedAnnouncements(selected) {
      this.loadingAlert = true;
      this.alertText = 'Reenviando correos...';
      this.showAlert = true;
      // console.log('Anuncios seleccionados', selected);
      const announcementIds = selected.map((announcement) => announcement.id);
      const { ok, message } = await resendAnnouncementEmails(announcementIds);
      if (ok) {
        // TODO: hacer que la selección se limpie
        // console.log('Se reenviaron los correos', data);

        this.showSuccessAlert('Se reenviaron los correos exitosamente');
      } else {
        // console.log('No se pudieron reenviar los correos', message);
        this.showErrorAlert('No se pudieron reenviar los correos. ' + message);
      }
    },
    showSuccessAlert(msg) {
      this.loadingAlert = false;
      this.alertType = 'info';
      this.alertColor = 'success';
      this.alertText = msg;
      // this.showAlert = true;
    },
    showErrorAlert(msg) {
      this.loadingAlert = false;
      this.alertType = 'error';
      this.alertText = msg;
      // this.showAlert = true;
    },
  },
};
